// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-clube-vantagens-js": () => import("./../../../src/pages/clube-vantagens.js" /* webpackChunkName: "component---src-pages-clube-vantagens-js" */),
  "component---src-pages-conteudo-cursos-js": () => import("./../../../src/pages/conteudo/cursos.js" /* webpackChunkName: "component---src-pages-conteudo-cursos-js" */),
  "component---src-pages-conteudo-e-books-js": () => import("./../../../src/pages/conteudo/e-books.js" /* webpackChunkName: "component---src-pages-conteudo-e-books-js" */),
  "component---src-pages-conteudo-js": () => import("./../../../src/pages/conteudo.js" /* webpackChunkName: "component---src-pages-conteudo-js" */),
  "component---src-pages-conteudo-videos-js": () => import("./../../../src/pages/conteudo/videos.js" /* webpackChunkName: "component---src-pages-conteudo-videos-js" */),
  "component---src-pages-conteudo-webinarios-js": () => import("./../../../src/pages/conteudo/webinarios.js" /* webpackChunkName: "component---src-pages-conteudo-webinarios-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-resultado-busca-js": () => import("./../../../src/pages/resultado-busca.js" /* webpackChunkName: "component---src-pages-resultado-busca-js" */),
  "component---src-pages-sobre-contato-js": () => import("./../../../src/pages/sobre/contato.js" /* webpackChunkName: "component---src-pages-sobre-contato-js" */),
  "component---src-pages-sobre-js": () => import("./../../../src/pages/sobre.js" /* webpackChunkName: "component---src-pages-sobre-js" */),
  "component---src-pages-sobre-perguntas-frequentes-js": () => import("./../../../src/pages/sobre/perguntas-frequentes.js" /* webpackChunkName: "component---src-pages-sobre-perguntas-frequentes-js" */),
  "component---src-pages-sobre-quem-somos-js": () => import("./../../../src/pages/sobre/quem-somos.js" /* webpackChunkName: "component---src-pages-sobre-quem-somos-js" */),
  "component---src-pages-sobre-representante-js": () => import("./../../../src/pages/sobre/representante.js" /* webpackChunkName: "component---src-pages-sobre-representante-js" */),
  "component---src-pages-solucoes-controle-estoque-js": () => import("./../../../src/pages/solucoes/controle-estoque.js" /* webpackChunkName: "component---src-pages-solucoes-controle-estoque-js" */),
  "component---src-pages-solucoes-controle-financeiro-js": () => import("./../../../src/pages/solucoes/controle-financeiro.js" /* webpackChunkName: "component---src-pages-solucoes-controle-financeiro-js" */),
  "component---src-pages-solucoes-gestao-performance-js": () => import("./../../../src/pages/solucoes/gestao-performance.js" /* webpackChunkName: "component---src-pages-solucoes-gestao-performance-js" */),
  "component---src-pages-solucoes-gestao-vendas-js": () => import("./../../../src/pages/solucoes/gestao-vendas.js" /* webpackChunkName: "component---src-pages-solucoes-gestao-vendas-js" */),
  "component---src-pages-solucoes-js": () => import("./../../../src/pages/solucoes.js" /* webpackChunkName: "component---src-pages-solucoes-js" */),
  "component---src-pages-solucoes-organizacao-fiscal-js": () => import("./../../../src/pages/solucoes/organizacao-fiscal.js" /* webpackChunkName: "component---src-pages-solucoes-organizacao-fiscal-js" */),
  "component---src-pages-solucoes-solucoes-complementares-js": () => import("./../../../src/pages/solucoes/solucoes-complementares.js" /* webpackChunkName: "component---src-pages-solucoes-solucoes-complementares-js" */)
}

