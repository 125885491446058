module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#0095da","theme_color":"#0095da","display":"minimal-ui","icon":"src/images/inovafarma-icon.png","icons":[{"src":"/favicons/android-chrome-48x48.png","sizes":"48x48","type":"image/png"},{"src":"/favicons/android-chrome-72x72.png","sizes":"72x72","type":"image/png"},{"src":"/favicons/android-chrome-96x96.png","sizes":"96x96","type":"image/png"},{"src":"/favicons/android-chrome-144x144.png","sizes":"144x144","type":"image/png"},{"src":"/favicons/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/favicons/android-chrome-256x256.png","sizes":"256x256","type":"image/png"},{"src":"/favicons/android-chrome-384x384.png","sizes":"384x384","type":"image/png"},{"src":"/favicons/android-chrome-512x512.png","sizes":"512x512","type":"image/png"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"202647287461975"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-83810265-1","head":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
